<template>
  <div>流量池</div>
</template>

<script>
export default {
  name: "flowPool"
}
</script>

<style scoped>

</style>